import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import Link from 'gatsby-link';
// import { Link as SLink } from 'react-scroll';
const SLink = loadable(() =>
  import('react-scroll').then(module => module.Link)
);

const NavItemsSmoothLinks = ({ NavItem }) => (
  <>
    <NavItem>
      <SLink smooth offset={-70} href="/" hashSpy to="home">
        Home
      </SLink>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-100} href="/#about" hashSpy to="about">
        About
      </SLink>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-100} href="/#work" hashSpy to="work">
        Work
      </SLink>
    </NavItem>
    <NavItem>
      <a href="https://art.seelay.in" target="blank">
        Artwork
      </a>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-300} href="/#contact" hashSpy to="contact">
        Contact
      </SLink>
    </NavItem>
  </>
);

const NavItemsGatsbyLinks = ({ NavItem }) => {
  const scrollToSection = sectionId => {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 250); // Adjust the timeout (in ms) to give time for delayed components to load
  };

  return (
    <>
      <NavItem>
        <Link aria-label="Home" href="/" to="/">
          Home
        </Link>
      </NavItem>
      <NavItem>
        <Link
          aria-label="About"
          href="/#about"
          to="/#about"
          onClick={() => scrollToSection('about')}
        >
          About
        </Link>
      </NavItem>
      <NavItem>
        <Link
          aria-label="Work"
          href="/#work"
          to="/#work"
          onClick={() => scrollToSection('work')}
        >
          Work
        </Link>
      </NavItem>
      <NavItem>
        <a
          role="link"
          target="blank"
          aria-label="Seelay Art"
          href="https://art.seelay.in"
        >
          Artwork
        </a>
      </NavItem>
      <NavItem>
        <Link
          aria-label="Contact"
          onClick={() => scrollToSection('contact')}
          to="/#contact"
        >
          Contact
        </Link>
      </NavItem>
    </>
  );
};

const NavLinks = React.memo(({ NavItem }) => {
  const [path, setPath] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPath(window.location.pathname);
    }
  }, []);

  if (path === null) return null; // Avoid rendering until we know the path

  return (
    <>
      {path === '/' ? (
        <NavItemsSmoothLinks NavItem={NavItem} />
      ) : (
        <NavItemsGatsbyLinks NavItem={NavItem} />
      )}
    </>
  );
});

export default NavLinks;
