const meta = {
  // Metadata
  siteTitle: 'Aman Seelay',
  siteDescription:
    "Multi-skilled product designer from India, passionate about blending creativity with data to shape intuitive, future-ready products that align with both people's needs and business goals.",
  siteTitleAlt: 'Seelay',
  siteShortName: 'Seelay',
  siteUrl: 'https://www.seelay.in', // No trailing slash!
};

const social = {
  siteLogo: `src/static/s-logo-red-maskable.svg`,
  siteBanner: `${meta.siteUrl}/images/social-banner.jpg`,
  twitter: '@imseelay',
};

const website = {
  ...meta,
  ...social,
  disqusShortName: 'seelay',
  // Manifest
  themeColor: '#FF2222',
  backgroundColor: '#FFFFFF',
};

module.exports = website;
